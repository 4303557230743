import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { Link, PageProps } from 'gatsby'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import { useGetModulesHook } from 'hooks/useGetModulesHook'
import { useGetTracking } from 'hooks/useGetTracking'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { $PropertyType, ValuesType } from 'utility-types'
import { get_activities } from '../../../../API/get_activities'
import { RepoMap } from '../../../../components/RepoMap'
import axios from 'axios'
import { getUrl } from 'API/getUrl'
import { getDefaultOptions } from 'API/auth'
import { ActivityComponent } from './ActivityComponent'
import { Icon } from '@iconify/react'
import { updateModuleDetails } from 'API/course'
import { elementAcceptingRef } from '@mui/utils'
import { getActivityStatus } from '../../../../API/get_activities'
import { navigate } from 'gatsby'
// import { useState } from 'react'
import { Row, Col, Button, Form, Modal } from 'react-bootstrap'
import { useAppDispatch } from 'redux/hooks'

import { ApplicationStateActions } from 'redux/slices/ApplicationState'
export type ActivityType = {
	__t:
		| 'ResourceRepository'
		| 'AssignmentRepository'
		| 'QuizRepository'
		| 'ForumRepository'
		| 'LectureRepository'
		| 'ExternalLinkRepository'
		| 'FeedbackRepository'

	description: string
	name: string
	visible_from: string
	available_from: string
	available_till: string
	uid: string
	module: null | {
		name: string
		uid: string
		index: number | string
	}
	proctor?: boolean
	video?: boolean
	lecture_type?: { name: string }
	non_assignment_type?: { name: string }
}

function UpcomingEvents({ course_id }: { course_id: string }) {
	const { tracking } = useGetTracking()

	return (
		<div className='card upcomingCard col-md-3 d-none d-lg-block '>
			<img className='bg-img' src={'/icons/calendar.svg'} alt='Calenadar' />
			<div className='upcomingContent'>
				<p className='lead pt-1 font-weight-bold'>Upcoming</p>
				{/* <h6 className=''>Woohoo, no work due soon!</h6> */}
				{/* './' + RepoMap[elem.__t] + (elem?.video ? '-video' : '') + '/' + elem.uid} */}
				<ul>
					{tracking
						?.filter((track) => track.course.uid === course_id)
						.filter((elem) => new Date(elem.available_till) > new Date())
						.map((elem, idx) => {
							if (idx < 3)
								return (
									<Link to={'./' + RepoMap[elem.__t] + (elem?.video ? '-video' : '') + '/' + elem.uid}>
										<li>
											{elem.name} - {RepoMap[elem.__t]?.toUpperCase()}
										</li>
									</Link>
								)
						})}
				</ul>
				<a href='/tracking' className=' text-dark font-weight-bolder'>
					View all
				</a>
			</div>
		</div>
	)
}
const filterType = [
	{
		id: 1,
		value: 'all',
		label: 'All',
	},
	{
		id: 2,
		value: 'quiz',
		label: 'Quiz',
	},
	{
		id: 3,
		value: 'assignment',
		label: 'Assignment',
	},
	{
		id: 4,
		value: 'feedback',
		label: 'Feedback',
	},
	{
		id: 5,
		value: 'lecture',
		label: 'Lecture',
	},
]

const ActivitiesList: React.FC<PageProps> = ({ params: { id } }) => {
	const { ShowDetails, viewAs, type_of_course, course, stable } = useGetCourseDetails(id)
	const [activities, setActivities] = React.useState<ActivityType[]>([])
	const [selected, setSelected] = useState('all')
	const [ActivityStatus, setActivityStatus] = useState([])
	useEffect(() => {
		const run = async () => {
			const status = await getActivityStatus(id)
			if (status) {
				console.log(status)
				setActivityStatus(status)
			}
		}
		if (viewAs === 'Student') {
			run()
		}
	}, [viewAs])
	React.useEffect(() => {
		const run = async () => {
			const m = await get_activities(id, viewAs)
			if (m) setActivities(m)
			console.log(m)
			m?.filter((elem) => {
				if (elem) {
					if (elem.non_assignment_type) {
						console.log(elem.non_assignment_type)
					} else {
						console.log('Quiz')
					}
				}
			})
		}
		run()
	}, [viewAs])

	// modulesAvailableTill
	const [moduleAvailableTill, setModuleAvailableTill] = React.useState<number>(1)

	const { modules } = useGetModulesHook({ course: (course as any)?.course?.uid || '', stable, type_of_course })

	React.useEffect(() => {
		if (type_of_course === 'Online') {
			setModuleAvailableTill(course?.current_module ?? 1)
			console.log({ course }, course?.current_module)
		}
	}, [course, type_of_course])

	return (
		<>
			{ShowDetails}

			<div className='container mt-2 mb-2' style={{ height: '100%' }}>
				<div className='row'>
					<div className='col-12'></div>
					<div className='col-12'>
						<div className='row justify-content-center ml-lg-auto'>
							<UpcomingEvents course_id={id}></UpcomingEvents>
							<div className='col-xs-12 col-lg-9'>
								<Select
									className='my-3'
									options={filterType}
									// value={questionTypes[elem.type - 1]}
									onChange={(e) => {
										if (e?.value) setSelected(e?.value)
									}}
								></Select>
								{/* <>
									<ModuleComponent
										moduleAvailableTill={moduleAvailableTill}
										course_id={id}
										activities={activities.filter((elem) => {
											return (
												elem.__t === 'LectureRepository' &&
												new Date(elem.available_from).getTime() < Date.now() &&
												new Date(elem.available_from).getTime() + ((elem as any)?.duration ?? 1) * 60 * 1000 > Date.now()
											)
										})}
										viewAs={viewAs}
										module={null}
										nameOfModule='Live Lectures'
									/>
								</> */}
								{type_of_course === 'Traditional' &&
									selected === 'all' &&
									activities.map((elem, idx) => {
										return (
											<ActivityComponent
												course_id={id}
												key={idx}
												activity_status={ActivityStatus}
												{...{ idx, elem, viewAs, moduleAvailableTill }}
											/>
										)
									})}
								{type_of_course === 'Traditional' &&
									selected === 'assignment' &&
									activities.map((elem, idx) => {
										return (
											elem.__t === 'AssignmentRepository' && (
												<ActivityComponent
													course_id={id}
													key={idx}
													activity_status={ActivityStatus}
													{...{ idx, elem, viewAs, moduleAvailableTill }}
												/>
											)
										)
									})}
								{type_of_course === 'Traditional' &&
									selected === 'lecture' &&
									activities.map((elem, idx) => {
										return (
											elem.__t === 'LectureRepository' && (
												<ActivityComponent
													course_id={id}
													key={idx}
													activity_status={ActivityStatus}
													{...{ idx, elem, viewAs, moduleAvailableTill }}
												/>
											)
										)
									})}
								{type_of_course === 'Traditional' &&
									selected === 'quiz' &&
									activities.map((elem, idx) => {
										return (
											elem.__t === 'QuizRepository' && (
												<ActivityComponent
													course_id={id}
													key={idx}
													activity_status={ActivityStatus}
													{...{ idx, elem, viewAs, moduleAvailableTill }}
												/>
											)
										)
									})}
								{type_of_course === 'Traditional' &&
									selected === 'feedback' &&
									activities.map((elem, idx) => {
										return (
											elem.__t === 'FeedbackRepository' && (
												<ActivityComponent
													course_id={id}
													key={idx}
													activity_status={ActivityStatus}
													{...{ idx, elem, viewAs, moduleAvailableTill }}
												/>
											)
										)
									})}
								{type_of_course === 'Online' && (
									<>
										{[null, ...modules].map((module) => {
											return (
												<>
													<ModuleComponent
														moduleAvailableTill={moduleAvailableTill}
														course_id={id}
														activities={activities}
														viewAs={viewAs}
														module={module}
													/>
												</>
											)
										})}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export const ModuleComponent: React.FC<{
	module: ValuesType<$PropertyType<ReturnType<typeof useGetModulesHook>, 'modules'>> | null
	activities: ActivityType[]
	moduleAvailableTill: number
	viewAs: 'Teacher' | 'Student'
	course_id: string
	nameOfModule?: string
}> = ({ viewAs, module, activities, course_id, nameOfModule, moduleAvailableTill }) => {
	const [isDisabled, setIsDisabled] = React.useState<boolean>(false)
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const [modalData, setModalData] = useState<any>({})
	const [ActivityStatus, setActivityStatus] = useState([])
	useEffect(() => {
		const run = async () => {
			const status = await getActivityStatus(course_id)
			if (status) {
				console.log(status)
				setActivityStatus(status)
			}
		}
		if (viewAs === 'Student') {
			run()
		}
	}, [viewAs])
	const dispatch = useAppDispatch()
	useEffect(() => {
		setModalData(module)
	}, [])
	useEffect(() => {
		if (module !== null) {
			setIsDisabled(module.index > moduleAvailableTill)
		}
	}, [module, moduleAvailableTill])
	console.log({ moduleAvailableTill }, 'tracking', module?.index, moduleAvailableTill)

	if (module === null || module === undefined) {
		return (
			<>
				<Accordion>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
						<Typography>{nameOfModule ?? 'Course Activities'}</Typography>
					</AccordionSummary>

					{activities
						.filter((elem) => {
							return elem.module === undefined || elem.module === null
						})
						.map((elem, idx) => {
							return (
								<AccordionDetails key={idx}>
									<ActivityComponent
										course_id={course_id}
										key={idx}
										activity_status={ActivityStatus}
										{...{ idx, elem, viewAs, moduleAvailableTill }}
									/>
								</AccordionDetails>
							)
						})}
				</Accordion>
			</>
		)
	} else
		return (
			<>
				<Accordion disabled={viewAs === 'Student' && isDisabled}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }} className='flex'>
							<Typography>
								Module {module.index}. {module.name}
							</Typography>
							{viewAs === 'Student' && isDisabled && (
								<>
									<div className='' style={{ display: 'flex', alignItems: 'center' }}>
										<div className=''>Complete Previous Module</div>
									</div>
								</>
							)}
							{viewAs === 'Teacher' && (
								<div className='' style={{ display: 'flex', alignItems: 'center' }}>
									<div
										onClick={async () => {
											const check = window?.confirm('Delete entire module?')
											if (check) {
												await axios.post(
													getUrl('module/delete'),
													{
														uid: module.uid,
														course: course_id,
													},
													{
														...getDefaultOptions(),
													}
												)
												if (window) window?.location?.reload()
											}
										}}
										style={{ backgroundColor: 'red', color: 'white', padding: '0.5rem', borderRadius: '8px', cursor: 'pointer' }}
										className=''
									>
										Delete
									</div>
									<div
										onClick={handleShow}
										style={{
											backgroundColor: 'green',
											color: 'white',
											padding: '0.5rem',
											borderRadius: '8px',
											cursor: 'pointer',
										}}
										className='mx-4'
									>
										Edit
									</div>
								</div>
							)}
							{/* {viewAs === 'Teacher' && (
								<div className='' style={{ display: 'flex', alignItems: 'center' }}>
									<div
										onClick={handleShow}
										style={{
											backgroundColor: 'green',
											color: 'white',
											padding: '0.5rem',
											borderRadius: '8px',
											cursor: 'pointer',
										}}
										className=''
									>
										Edit
									</div>
								</div>
							)} */}
						</div>
					</AccordionSummary>
					{activities
						.filter((elem) => {
							// console.log(elem.module?.uid === module.uid, elem.name)
							return elem.module?.uid === module.uid
						})
						.map((elem, idx) => {
							return (
								<>
									<AccordionDetails>
										<ActivityComponent
											course_id={course_id}
											key={idx}
											activity_status={ActivityStatus}
											{...{ moduleAvailableTill, idx, elem, viewAs }}
										/>
									</AccordionDetails>
								</>
							)
						})}
				</Accordion>
				<>
					<Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} size='lg' centered>
						<form
							onSubmit={async (e) => {
								e.preventDefault()
								// await addUser(modalData)
								await updateModuleDetails(modalData.name, modalData.uid, modalData.index, course_id)
								// handleClose()
								console.log(modalData)
								dispatch(ApplicationStateActions.setUpdate())
							}}
						>
							<Modal.Header closeButton>
								<Modal.Title>Edit Module</Modal.Title>
							</Modal.Header>

							<Modal.Body>
								<Row></Row>

								<Row>
									<Col lg={12}>
										<Form.Group controlId='txtCourseName'>
											<Form.Label>Module Name</Form.Label>
											<input
												className='form-control'
												required
												type='text'
												placeholder='Course Name'
												defaultValue={modalData.name}
												onChange={(e) => {
													setModalData({
														...modalData,
														name: e.target.value,
													})
												}}
											/>
										</Form.Group>
									</Col>

									{/* <Col lg={12}>
										<Form.Group controlId='txtDescription'>
											<Form.Label>Index</Form.Label>
											<input
												className='form-control'
												required
												type='number'
												defaultValue={modalData.index}
												placeholder='Index'
												onChange={(e) => {
													setModalData({
														...modalData,
														index: parseInt(e.target.value),
													})
												}}
											/>
										</Form.Group>
									</Col> */}

									{/* <Col lg={6}>
										<Form.Group controlId='txtEndDate'>
											<Form.Label>End Date</Form.Label>
											<input
												className='form-control'
												required
												type='datetime-local'
												placeholder='End Date'
												onChange={(e) => {
													setModalData({
														...modalData,
														end_date: e.target.value,
													})
												}}
											/>
										</Form.Group>
									</Col> */}

									{/* <Col lg={6}>
										<Form.Group controlId='txtCategory'>
											<Form.Label>Category</Form.Label>
											<input
												className='form-control'
												required
												type='text'
												placeholder='Category'
												onChange={(e) => {
													setModalData((modalData) => ({
														...modalData,
														course_tag: e.target.value,
													}))
												}}
											/>
										</Form.Group>
									</Col> */}
								</Row>
							</Modal.Body>

							<Modal.Footer>
								<Button variant='secondary' onClick={handleClose}>
									Close
								</Button>
								<Button type={'submit'} variant='primary'>
									Save changes
								</Button>
							</Modal.Footer>
						</form>
					</Modal>
				</>
			</>
		)
}

export default ActivitiesList
