import { getTracking } from 'API/user'
import React from 'react'
import { useState } from 'react'
import { PromiseType } from 'utility-types'

export const useGetTracking = () => {
	const [tracking, setTracking] = useState<PromiseType<ReturnType<typeof getTracking>>>()
	React.useEffect(() => {
		const run = async () => {
			setTracking(await getTracking())
		}
		run()
	}, [])

	return { tracking }
}
