import { getDefaultOptions } from 'API/auth'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { ActivityType } from '../pages/course/[id]/activities/index'

export async function get_activities(id: string, viewAs: string): Promise<ActivityType[] | undefined> {
	if (id === 'Online-Course-Test-1002') {
		return []
	} else
		return await handleApi(axios.get<ActivityType[]>(getUrl(`asset/all?course_id=${id}&asTeacher=${viewAs === 'Teacher'}`), getDefaultOptions()))
}

export async function getActivityStatus(id: string) {
	if (id === 'Online-Course-Test-1002') {
		return undefined
	} else {
		return await handleApi(axios.get<any>(getUrl(`grades/activity_status?course_id=${id}`), getDefaultOptions()))
	}
}
